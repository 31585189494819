<template>
  <div class="container-productdetails">
    <div class="productdetails-header">
      <div class="productdetails-header-left">
        <div class="productdetails-img">
          <a href="javascript:;" @click="topImg()">
            <i class="iconfont icon-xiangshang"></i>
          </a>
          <img :src="item.url" alt="" v-for="(item,index) in imgList" :key="index" :class="num===index?'active':''">
          <a href="javascript:;" @click="bottomImg()">
            <i class="iconfont icon-xiala"></i>
          </a>
        </div>
      </div>
      <div class="productdetails-header-middle">
        <img :src="index===num?item.url:''" alt="" v-for="(item,index) in imgList" :key="index">
      </div>
      <div class="love-toggle">
        <div :class="status===false?'love':'activeA'" @click="tabImgColor"></div>
      </div>
      <div class="productdetails-header-right">
        <div class="productdetails-header-right-top">
          <h1>
            {{auctionItemInfoListData.title}}
          </h1>
          <div class="item">
            <span class="label">拍品估价</span>
            <span>:</span>
            <span class="value">{{auctionItemInfoListData.assess}}&nbsp;{{item.currency}}</span>
          </div>
          <div class="item">
            <span class="label">起拍价格</span>
            <span>:</span>
            <span class="value">{{auctionItemInfoListData.initialPrice}}&nbsp;{{item.currency}}</span>
          </div>
          <div class="item">
            <span class="label">买家佣金</span>
            <span>:</span>
            <span class="value">30&nbsp;元</span>
          </div>
          <div class="item">
            <span class="label">平台服务费</span>
            <span>:</span>
            <span class="value">34&nbsp;元</span>
          </div>

          <div class="productdetails-pirce">
            <span>联系客服微信：2334442322</span>
            <span>
              <a href="javascript:">立即出价</a>
            </span>
          </div>
        </div>
        <div class="productdetails-header-right-bottom">
          <div class="productdetails-header-right-bottom-text">
            <!-- <div class="productdetails-text-left">
              <p>拍卖行：</p>
              <p>艺德拍卖</p>
            </div>
            <div class="productdetails-text-left">
              <p>开拍时间：</p>
              <p>08月30日上午9点 | 北京时间</p>
            </div>
            <div class="productdetails-text-left">
              <p>拍卖地点：</p>
              <p>宇宙中心饭店</p>
            </div> -->
            <div class="item">
              <span class="label">拍卖行</span>
              <span>:</span>
              <span class="value">{{auctionHouseTitle}}</span>
            </div>
            <div class="item">
              <span class="label">开拍时间</span>
              <span>:</span>
              <span class="value">{{auctionItemInfoData.auctionTime}} | 北京时间</span>
            </div>
            <div class="item">
              <span class="label">拍卖地点</span>
              <span>:</span>
              <span class="value">{{location}}</span>
            </div>
          </div>
          <div class="productdetails-header-right-bottom-block">
            <a href="javascript:" @click="goOut()">浏览本场拍卖会</a>
          </div>
        </div>
      </div>
    </div>
    <div class="productdetails-collapse">
      <a-collapse accordion>
        <a-collapse-panel key="1" header="拍品描述" :disabled="false">
          <div class="productdetails-header-translation">
            <i class="iconfont icon-fanyi"></i>
            <a href="javascript:" @click="translation()">
              翻译
            </a>
          </div>
          <p style="text-align:justify;"> {{auctionItemInfoListData.itemOverview}}</p>
          <div class="productdetails-collapse-line" v-show="collapseShow">
            <div class="productdetails-collapse-line-top">
              <span></span>
              <span>以下为第三方软件翻译，仅供参考</span>
              <span></span>
            </div>
            <div class="collapse-text">
              {{auctionItemInfoListData.chineseTitle}}
            </div>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="付款&运输">
          <p style="text-align:justify;">{{auctionItemInfoListData.paymentShipping}}</p>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="拍卖条款">
          <p style="text-align:justify;">{{auctionItemInfoListData.terms}}</p>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>
<script>
  import {
    auctionItemInfo,
    userCollect
  } from '../../api/index'
  export default {
    name: 'Productdetails',
    data() {
      return {
        collapseShow: false,
        imgList: [],
        num: 0,
        auctionItemInfoData: {},
        auctionItemInfoListData: {},
        location: '',
        auctionHouseTitle: '',
        productdetailsInfo: {},
        status: null
      }
    },
    created() {
      // this.auctionItemInfoData = JSON.parse(this.$route.query.productdetailsInfo)
      this.productdetailsInfo = JSON.parse(this.$route.query.productdetailsInfo)
      this.status = this.productdetailsInfo.status

      this.location = this.$route.query.location
      this.auctionHouseTitle = this.$route.query.auctionHouseTitle
      this.auctionItemInfo()
    },
    methods: {
      handleChange(val) {

      },
      tabImgColor(id) {
        if (!sessionStorage.getItem('accessToken')) {
          this.$message.error('请您先登录~')
        } else {
          this.userCollect(id)
          this.status = !this.status
        }
      },
      // 用户点击收藏
      async userCollect(id) {
        const res = await userCollect({
          userid: this.$store.state.userId,
          itemid: this.productdetailsInfo.id
        })
        if (res.code === 0) {
          this.$message.success('收藏成功~')
          this.auctionItemInfo()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 详情数据初始化
      async auctionItemInfo() {
        const res = await auctionItemInfo({
          id: this.productdetailsInfo.id,
          userId: this.$store.state.userId || 0
        })
        if (res.code === 0) {
          this.auctionItemInfoListData = res.data
          this.imgList = res.data.images
        } else {
          this.$message.error(res.msg)
        }
      },
      // 浏览本场拍卖会
      goOut() {
        this.$router.go(-1)
      },
      // 点击翻译
      translation() {
        this.collapseShow = !this.collapseShow
      },
      // 向上切换
      topImg() {
        if (this.num <= this.imgList.length - 1) {
          if (this.num > 0) {
            this.num--
          } else {
            this.num = this.imgList.length - 1
          }
        }
      },
      // 向下切换
      bottomImg() {
        if (this.num < this.imgList.length - 1) {
          this.num++
        } else {
          this.num = 0
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-productdetails {
    margin: 0 90px;
    box-sizing: border-box;

    /deep/ .ant-collapse-header {
      text-align: left;
      padding: 30px 5px;
      font-size: 24px;
    }

    /deep/ .ant-collapse {
      background-color: #fff;
      border: none;
      position: relative;
    }

    /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
      left: 1690px;
      font-size: 24px;
    }

    // /deep/ .ant-collapse-item {
    //   height: 93px;
    // }
    .productdetails-collapse {
      p {
        text-align: left;
      }

      .productdetails-header-translation {
        display: flex;
        position: absolute;
        top: 28px;
        left: 115px;
        width: 70px;
        height: 25px;
        border: 1px solid #cda156;
        border-radius: 4px;
        align-items: center;
        justify-content: center;

        i {
          color: #cda156;
        }

        a {
          color: #cda156;
          padding-left: 5px;
          font-size: 14px;
          // margin-left: 40px;
        }
      }
    }

    .productdetails-collapse-line {
      display: flex;
      flex-direction: column;

      .collapse-text {
        text-align: left;
      }

      .productdetails-collapse-line-top {
        display: flex;
        align-items: center;
        margin: 40px 0;

        span:nth-child(1) {
          width: 722px;
          height: 1px;
          background: #d8d8d8;
        }

        span:nth-child(2) {
          font-size: 12px;
          color: #999;
          font-weight: 400;
          padding: 0 40px;
        }

        span:nth-child(3) {
          width: 722px;
          height: 1px;
          background: #d8d8d8;
        }
      }
    }

    .active {
      border: 4px solid #cda156;
    }

    .productdetails-header {
      display: flex;
      margin-top: 63px;
      padding-bottom: 60px;
      border-bottom: 1px solid #dedede;

      .productdetails-header-left {
        a {
          color: #000;

          i {
            font-size: 36px;
          }
        }

        .productdetails-img {
          display: flex;
          flex-direction: column;

          img {
            width: 100px;
            height: 100px;
            margin: 26px 0;
          }
        }
      }

      .productdetails-header-middle {
        width: 670px;
        height: 670px;
        margin: 0 70px;
        // padding: 0 70px;
        overflow: hidden;

        img {
          width: 670px;
        }
      }

      .love:hover {
        background: url("../../assets/images/17.png") no-repeat;
        background-size: 40px;
      }

      .activeA {
        background: url("../../assets/images/17.png") no-repeat !important;
        background-size: 40px !important;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        right: 10px;
        margin: 0;
        background-size: 40px;
        border: transparent;
        border-radius: 50%;
      }

      .love {
        width: 40px;
        height: 40px;
        background: url("../../assets/images/9.png") no-repeat;
        background-size: 40px;
        border: transparent;
        border-radius: 50%;
      }

      .productdetails-header-right {
        margin-left: 30px;

        .productdetails-header-right-top {
          display: flex;
          flex-direction: column;
          align-items: self-end;
          border-bottom: 1px solid #dedede;

          h1 {
            font-size: 36px;
            font-weight: 400;
            color: #333;
            text-align: left;
            margin: 0 0 30px 0;
          }

          .item {
            height: 32px;
            line-height: 32px;
            margin-bottom: 8px;
            font-size: 20px;

            span:nth-child(2) {
              padding: 0 20px;
            }
          }

          .label {
            display: inline-block;
            width: 120px;
            height: 100%;
            text-align: justify;
            text-align-last: justify;
            font-size: 20px;
          }

          p {
            margin-bottom: 20px;
            color: #333;

            span:nth-child(2) {
              font-weight: 600;
            }
          }

          .productdetails-pirce {
            width: 484px;
            height: 50px;
            margin: 30px 0;
            border-radius: 8px;
            border: 1px solid #999;
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            span:nth-child(1) {
              width: 290px;
              height: 100%;
              color: #333;
              font-size: 18px;
            }

            span:nth-child(2) {
              width: 200px;
              height: 100%;

              a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: #cda156;
                color: #fff;
                font-weight: 600;
                font-size: 18px;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
              }
            }
          }
        }

        .productdetails-header-right-bottom {
          margin-top: 30px;

          .productdetails-header-right-bottom-text {
            font-size: 18px;
            font-weight: 400;
            color: #333;
            text-align: left;

            .item {
              height: 32px;
              line-height: 32px;
              margin-bottom: 8px;

              span:nth-child(2) {
                padding: 0 20px;
              }
            }

            .label {
              display: inline-block;
              width: 80px;
              height: 100%;
              text-align: justify;
              text-align-last: justify;
            }

            &:after {
              display: inline-block;
              width: 100%;
              content: "";
              height: 0;
            }

            .value {
              padding-right: 6px;
            }
          }

          .productdetails-header-right-bottom-block {
            display: flex;

            a:nth-child(1) {
              width: 290px;
              height: 50px;
              border-radius: 8px;
              border: 1px solid #cda156;
              line-height: 50px;
              text-align: center;
              color: #cda156;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
</style>
